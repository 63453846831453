export default {
  breakpoints: ["40em", "52em", "65em"], //["26em", "68em", "125em"], // mobile -> tablet, tablet -> desktop, desktop -> widescreen
  space: [
    "0rem",
    "0.9375rem", //15px 1
    "1.125rem", //18px 2
    "1.6875rem", //27px 3
    "2.5rem", //40px 4
    "2.625rem", //42px 5
    "3.4375rem", //55px 6
    "5.9375rem", //95px 7
    "9.6875rem", //155px 8
    "6.25rem", //100px 9
    "5rem", //80px 10
    "3.125rem", //50px 11
  ],
  fonts: {
    body:
      'Lato, Helvetica, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    title:
      'Lato, Helvetica, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    monospace: "Menlo, monospace",
  },
  fontSizes: [
    "0rem",
    "0.875rem", //14px 1
    "1.0625rem", //17px 2
    "1.125rem", //18px 3
    "1.25rem", //20px 4
    "1.3125rem", //21px 5
    "1.4375rem", //23px 6
    "1.5rem", //24px 7
    "1.875rem", //30px 8
    "2.1875rem", //35px 9
    "2.5625rem", //41px 10
    "3.75rem", //60px 11
  ],
  sizes: [
    "0rem",
    "0.1rem", //1
    "0.2rem", //2
    "0.5rem", //3
    "1rem", //4
    "1.5rem", //5
    "2rem", //6
    "2.5rem", //7
    "3rem", //8
    "3.5rem", //9
    "4rem", //10
    "4.5rem", //11
    "5rem", //12
    "6rem", //13
    "7rem", //14
    "8rem", //15
    "12rem", //16
    "16rem", //17
    "32rem", //18
    "40rem", //19
  ],
  fontWeights: {
    body: 300,
    title: 800,
    regular: 300,
    telAndMail: 400,
    semibold: 600,
    bold: 800,
  },
  lineHeights: {
    regular: 1.5,
    headlines: 1.2,
  },
  colors: {
    text: "#ffffff",
    background: "#3c3c3b",
    background2: "#575756",
    one: "#d84848",
    two: "#f09018",
  },
  // variants can use custom, user-defined names:
  text: {
    title: {
      fontFamily: "title",
      lineHeight: "title",
      fontWeight: "bold",
    },
    body: {
      fontFamily: "body",
      lineHeight: "regular",
      fontWeight: "regular",
      fontSize: [1, 2, 2],
    },
  },
  // variants for buttons
  buttons: {
    cta: {
      fontFamily: "Lato",
      color: "text",
      textDecoration: "none",
      bg: "two",
      px: ["0.5rem", "1rem", "1rem"],
      py: ["0.5rem", "1rem", "1rem"],
      fontSize: [4, 7, 7],
      fontWeight: 400,
      textAlign: "center",
      border: "none",
      cursor: "pointer",
    },
    one: {
      // you can reference other values defined in the theme
      color: "background",
      bg: "one",
    },
    two: {
      color: "background",
      bg: "two",
    },
  },
  hs: {
    h1: {
      variant: "text.title",
      fontSize: [8, 11, 11],
      lineHeight: "headlines",
      m: 0,
    },
    title: {
      variant: "text.title",
      fontSize: [3, 10, 10],
      m: 0,
    },
    subtitle: {
      variant: "text.title",
      fontSize: [3, 7, 7],
      lineHeight: "headlines",
      m: 0,
      fontWeight: "semibold",
    },
    h2: {
      variant: "text.title",
      fontSize: [7, 9, 9],
      lineHeight: "headlines",
      m: 0,
    },
    h3: {
      variant: "text.title",
      fontSize: [3, 7, 7], //WTF
      lineHeight: "headlines",
      m: 0,
      fontWeight: "regular",
    },
    h4: {
      variant: "text.title",
      fontSize: [5, 6, 6],
      lineHeight: "headlines",
      m: 0,
    },
    h5: {
      variant: "text.body",
      fontSize: [5, 7, 7],
      lineHeight: "headlines",
      m: 0,
    },
    h6: {
      variant: "text.body",
      lineHeight: "headlines",
      fontSize: 0,
    },
  },
  //Example styles
  styles: {
    root: {
      "::-webkit-scrollbar-thumb": {
        background: "one",
      },
      "@font-face": {
        fontDisplay: "block",
      },
      fontFamily: "body",
      lineHeight: "regular",
      fontWeight: "regular",
      fontSize: "16px",
    },
  },
};
